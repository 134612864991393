import React, { useRef, useEffect, useState } from "react";
import { FirebaseService } from "../../services/FirebaseService";
import { getDateAsInputString } from "../../common/UtilitiyFunctions";
import {
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import showToast from "../../common/toastify";
import { useTimer } from 'react-timer-hook';
import { getDefaultCallingCountryCode } from "../../common/UtilitiyFunctions";
import { DatePicker } from '@mantine/dates';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import ProfileGoogleAddressPickerComponent from '../../common/GoogleMap/ProfileGoogleAddressPickerComponent';

const currentDate = new Date()
currentDate.setFullYear(currentDate.getFullYear() - 18);
const PhoneLoginComponent = ({ phone, mode, formFields: fFields, setFormFields: setFFields,oldAddressValue,setOldAddressValue, ...props }) => {

  const digitRegex = /^\d+$/;

  const callingCodes = [
    {
      name: "US",
      code: "+1",
      flag: `US`,
    },
    {
      name: "India",
      code: "+91",
      flag: "IND",
    },
  ];
  const resendInterval = 60;        // 30 SECONDS
  const [isDisabled, setIsDisabled] = useState(true);
  const ct = new Date();
  ct.setSeconds(ct.getSeconds() + resendInterval);

  const {
    seconds,
    restart
  } = useTimer({
    expiryTimestamp: ct, onExpire: () => {
      setIsDisabled(false);
    }
  });

  const phoneRef = useRef(null);
  const otp1Ref = useRef(null);
  const otp2Ref = useRef(null);
  const otp3Ref = useRef(null);
  const otp4Ref = useRef(null);
  const otp5Ref = useRef(null);
  const otp6Ref = useRef(null);

  const [formFields, setFormFields] = useState({
    phone: phone || "",
    first_name: fFields?.first_name || "",
    last_name: fFields?.last_name || "",
    dob: "",
    countryCode: "",
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
    referral:fFields?.referral || "",
    marketing_email_subscription:fFields?.marketing_email_subscription ?? false,
    address: fFields.address || "",
    city: fFields.city || "",
    state:fFields.state || "",
    country: fFields.country || "",
    zip: fFields.zip || "",
    lat: fFields.lat || "",
    long: fFields.long || "",
  });

  const [auth, setAuth] = useState(FirebaseService.auth);
  const [currentDisplayForm, setCurrentDisplayForm] = useState("mobilenumber");

  const [disabled, setDisabled] = useState(false);
  const [otpDisabled, setOtpDisabled] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const getCallingCountryCode = async () => {
    const data = await getDefaultCallingCountryCode();
    if (data) {
      setFormFields({ ...formFields, countryCode: data });
    }
  };

  const checkValidation = () => {
    const isFirstName = /^(?!\s*$).+/.test(formFields.first_name || "");
    const isLastName = /^(?!\s*$).+/.test(formFields.last_name || "");
    const isPhone = /^(?!\s*$).+/.test(formFields.phone || "");    
    const isDOB = /^(?!\s*$).+/.test(formFields.dob || "");
    const isReferral = /^(?!\s*$).+/.test(formFields.referral || "");
    const isInvalidPhone = /^[0-9]{10,10}$/.test(formFields.phone || "");

    if(!isFirstName) showToast("Please enter your legal first name", "error");
    else if(!isLastName) showToast("Please enter your legal last name", "error");
    else if(isPhone && !isInvalidPhone) showToast("Please enter your correct email id / phone number", "error");
    else if(!isPhone) showToast("Please enter your phone number", "error");
    else if(!isDOB) showToast("Please select your date of birth", "error");
    else if(!isReferral) showToast("Please let us know how did you hear about Cheezoo")

    if(!isFirstName || !isLastName || !isPhone || !isInvalidPhone || !isDOB || !isReferral) return false;
    return true;
  }

  const handlePhoneNumberSubmit = async (e) => {
    e.preventDefault();
    const number = formFields.countryCode + formFields.phone;

    // check if user exists in case of login
    
    const resp = await fetch(
      `/user-exists?phone=${encodeURIComponent(number)}`
    );
    const data = await resp.json();

    if (mode == "login" && !data.exists) {
        setOtpDisabled(false);
        showToast("User not found. Please create your account first", "error");
        return;
    }
  


    if(mode == 'register'){
      if (!checkValidation()) return;
      if (!formFields.address) {
        if (window.location.href.includes('/register-client')) {
          showToast("Please enter your valid zip code", "error");
        } else {
          showToast("Please enter your valid address", "error");
        }
        return;
      }
      if (data.exists) {
        setOtpDisabled(false);
        showToast("The account already exists for that phone. Please login", "error");
        return;
      }
    }

    setDisabled(true);
    setResendDisabled(true);
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, number, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        showToast("SMS sent", "success");
        setIsDisabled(true);
        setResendDisabled(false);
        const time = new Date();
        time.setSeconds(time.getSeconds() + resendInterval);
        restart(time);
        setCurrentDisplayForm("otp");
        setFormFields({
          ...formFields,
          otp1: "",
          otp2: "",
          otp3: "",
          otp4: "",
          otp5: "",
          otp6: "",
        });
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
        console.log("eror occured :", error.message);
        window.grecaptcha.reset(window.recaptchaWidgetId);
        // Or, if you haven't stored the widget ID:
        window.recaptchaVerifier.render().then(function (widgetId) {
          window.grecaptcha.reset(widgetId);
          const recaptchaContainer = document.getElementById('recaptcha-container');

          // Add an event listener to the entire document
          document.addEventListener('click', function(event) {
            // Check if the clicked element is outside the reCAPTCHA container
            if (!recaptchaContainer.contains(event.target)) {
              setDisabled(false);        
              setOtpDisabled(false);
              console.log('Clicked outside reCAPTCHA widget');  
              // Optionally, you can reset the captcha:
              // window.grecaptcha.reset(widgetId);
            }
          }.bind(this));
        
        }).catch(function (error) {       
          setDisabled(false);        
          setOtpDisabled(false);
           console.error("reCAPTCHA rendering failed: ", error);
        })
        .finally(function () {
          setDisabled(false);        
          setOtpDisabled(false);
           console.log("reCAPTCHA process completed (success or error).");
        });
        // setCurrentDisplayForm("mobilenumber");
        // Reset state if screen changes
        setDisabled(false);
        setResendDisabled(false);
        setOtpDisabled(false);
        if (error.message === "Firebase: Error (auth/too-many-requests).") {
          showToast(
            "You have reached the maximum number of attempts. Please wait 2 minutes before trying again",
            "error"
          );
        } else if (error?.code === 'auth/session-expired') {
          showToast(
            "The sms code has expired. Please re-send the verification code to try again.",
            "error"
          );
        } else {
          showToast(error?.message, "error");
        }
      });
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    try {
      setOtpDisabled(true);
      const otp = `${formFields.otp1}${formFields.otp2}${formFields.otp3}${formFields.otp4}${formFields.otp5}${formFields.otp6}`;
      const result = await window.confirmationResult.confirm(otp);
      const firebaseUser = result.user;
      const credential = PhoneAuthProvider.credential(
        window.confirmationResult.verificationId,
        otp
      );

      if (props.onUserSignIn) {
       const error = await props.onUserSignIn(firebaseUser, credential, "phone",formFields);
        if(error === true) setOtpDisabled(false);
      }
    } catch (err) {
      if (err.message === "Firebase: Error (auth/invalid-verification-code).") {
        showToast("Please enter correct OTP", "error");
        setOtpDisabled(false);
      } else if (err.message === "Firebase: Error (auth/code-expired).") {
        setOtpDisabled(false);
        showToast(
          "one-time password session has expired, please click on resend one-time password button to get new one",
          "error"
        );
      } else if (err.message === "Firebase: Error (auth/error-code:-39).") {
        setOtpDisabled(false);
        showToast(
          "You have reached the maximum number of attempts. Please wait 2 minutes before trying again",
          "error"
        );
      } else if (err?.code === 'auth/session-expired') {
        setOtpDisabled(false);
        showToast(
          "The sms code has expired. Please re-send the verification code to try again.",
          "error"
        );
      } else {
        setOtpDisabled(false);
        showToast(err.message, "error");
      }
    }
  };

  
  const handleBackspace= (e, inputRef) => {
    if (e.key !== 'Backspace' && e.target.value !== '' && (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+')) {
      e.preventDefault();
    }
    if (e.key === 'Backspace' && e.target.value === '') {
      inputRef.current.focus();
    }
  }
  
  const intializeRecaptcha = () => {
    // Commenting out the reCAPTCHA initialization for testing
    window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
            size: "invisible",
            callback: (response) => {
                setIsRecaptchaSolved(true);
            },
            "expired-callback": () => {
                setIsRecaptchaSolved(false);
            },
        },
    );
  };

  useEffect(() => {
    if (!auth) {
      return;
    }
    intializeRecaptcha();
  }, [auth]);

  useEffect(() => {
    if (phoneRef.current && mode == 'login') {
      phoneRef.current.focus();
    }
  }, [phoneRef]);

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');
    const otpValues = pastedText.split('');

    if (otpValues.length === 6) {
      setFormFields({
        ...formFields,
        otp1: otpValues[0],
        otp2: otpValues[1],
        otp3: otpValues[2],
        otp4: otpValues[3],
        otp5: otpValues[4],
        otp6: otpValues[5],
      });
    }
  };
  useEffect(() => {
    if (props.onInputChange) {
      props.onInputChange(formFields.phone);
    }
  }, [formFields.phone]);

  useEffect(() => {
    if (!auth) {
      setAuth(FirebaseService.auth);
    }
    getCallingCountryCode();
  }, []);


  useEffect(() => {
    if(fFields && setFFields)
    setFFields({...fFields,...formFields})
  }, [formFields])
  
  return (
    <div className="phone-login-component">
      {currentDisplayForm === "mobilenumber" && (
        <form onSubmit={(e) => handlePhoneNumberSubmit(e)}>

          <div>
            {/* <div className="col-4">
              <div className="mb-4">
                <select
                  className="form-control form-control-user select-box-custom"
                  id="countryCode"
                  value={formFields.countryCode}
                  onChange={(e) =>
                    setFormFields({
                      ...formFields,
                      countryCode: e.target.value,
                    })
                  }
                >
                  {callingCodes.map((callingCode, index) => (
                    <option key={index} value={callingCode.code}>
                      {callingCode.flag} {callingCode.code}
                    </option>
                  ))}
                </select>
              </div>
            </div> */}
            {mode == "register" && (
              <>
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    id="FirstName"
                    placeholder="Legal First Name"                    
                    value={formFields.first_name}
                    onChange={(e) =>
                      setFormFields({ ...formFields, first_name: e.target.value })
                    }
                    onBlur={(e) => {
                      setFormFields({ ...formFields, first_name: formFields.first_name.trim() })
                    }}
                  />
                  <label htmlFor="FirstName">Legal First Name</label>
                </div>
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    id="LastName"
                    placeholder="Legal Last Name"
                    value={formFields.last_name}
                    onChange={(e) =>
                      setFormFields({ ...formFields, last_name: (e.target.value) })
                    }
                    onBlur={(e) => {
                      setFormFields({ ...formFields, last_name: formFields.last_name.trim() })
                    }}
                  />
                  <label htmlFor="LastName">Legal Last Name</label>
                </div>
              </>
            )}
            <div className="col-12">
              <div className="form-floating mb-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Phone Number..."                  
                  ref={phoneRef}
                  value={formFields.phone}
                  onChange={(e) => {
                    setFormFields({ ...formFields, phone: e.target.value })
                    // setInputValue(e.target.value);
                  }}
                />
                <label htmlFor="Phone">Phone or Email</label>
              </div>
            </div>
          </div>
          {mode == "register" && (<>
            <div className="form-floating date-input-format mb-4">
              {/* <input
                  type="date"
                  className="form-control"
                  id="dob"
                  placeholder="Date of Birth"
                  required
                  max={getDateAsInputString(new Date())}
                  value={formFields.dob}
                  onChange={(e) =>
                    setFormFields({ ...formFields, dob: e.target.value })
                  }
                /> */}
              {/* <DatePicker
                  value={formFields.dob}
                  initialMonth={formFields.dob ? moment(formFields.dob).toDate() : moment(currentDate).toDate()}
                  maxDate={moment(currentDate).toDate()}
                  onChange={(e) => {
                    if (!e) return;
                    setFormFields({ ...formFields, dob: moment(e).format('YYYY-MM-DD') })
                  }
                  }
                  required
                  placeholder="Date of birth"
                  maw={400}
                  mx="auto"
                /> */}
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileDatePicker
                  format="LL"
                  value={moment(formFields.dob)}
                  maxDate={moment(currentDate)}
                  onChange={(e) => {
                    if (!e) return;
                    setFormFields({ ...formFields, dob: moment(e).format('YYYY-MM-DD') })
                  }
                  }
                />
              </LocalizationProvider>
              {/* <label htmlFor="dob">Date of birth</label> */}
            </div>
            <div className="mb-4">
            {/* <label htmlFor="referral" className="p_user_name font16">How did you hear about Cheezoo?</label> */}
            <select
              className={
                "form-select"}
              id="referral_phone"
              name="referral_phone"
              value={formFields.referral}
              onChange={(e) => {
                setFormFields({ ...formFields, referral: e.target.value });
              }}
              style={{color: formFields?.referral == "" ? "var(--inputfilttext)" : "var(--gray)"}}
            >
              <option value="" disabled style={{color: "var(--inputfilttext)"}}>How did you hear about Cheezoo?</option>
              <option value="Google" style={{color: "var(--gray)"}} >Google</option>
              <option value="Instagram" style={{color: "var(--gray)"}} >Instagram</option>
              <option value="Facebook" style={{color: "var(--gray)"}} >Facebook</option>
              <option value="TikTok" style={{color: "var(--gray)"}} >TikTok</option>
              <option value="YouTube" style={{color: "var(--gray)"}} >YouTube</option>
              <option value="LinkedIn" style={{color: "var(--gray)"}} >LinkedIn</option>
              <option value="Friend/Family" style={{color: "var(--gray)"}} >Friend/Family</option>
              <option value="Other" style={{color: "var(--gray)"}} >Other</option>
            </select>
          </div>
          </>
          )}
          {mode == "register" &&<><ProfileGoogleAddressPickerComponent
              types= {location.href.includes('register-client') ? {types: ['postal_code']} : null}
              inputLabel = {<></>}
              useDefaultCurrentLocation={false}
              showInfoModal={true}
              showMap={false}
              userAddress={formFields}
              className="d-flex align-items-center input-group mb-2" 
              locationError={true}
              locationErrorMessage={null}
              oldAddressValue={oldAddressValue}
              setOldAddressValue={setOldAddressValue}
              onChange={(addrComponent) => {
                // if(!addrComponent?.addrLine1) return;
                setFormFields({
                  ...formFields,
                  address:
                    addrComponent?.addrLine1 || addrComponent?.address || "",
                  city: addrComponent.city,
                  state: addrComponent.state,
                  country: addrComponent.country,
                  zip: addrComponent.zip,
                  lat: addrComponent.lat,
                  long: addrComponent.lng,
                });
              }}
            /></> }
          {mode == "register" && <div
            style={{
              position: "relative",
              alignItems: "center",
              marginBottom: "10px"
            }}
            htmlFor="check" className="d-flex">
            <input type="checkbox" checked={formFields.marketing_email_subscription}
              onChange={e => setFormFields({
                ...formFields,
                marketing_email_subscription: e.target.checked,
              })}
              id="check" class="g_checkbox form-check-input" style={{
                position: "relative",
                left: "0px",
                top: "0px",
                marginRight: "10px"
              }} />Subscribe to emails for tips, tricks, and recommendations</div>
          }
          <button type="submit" disabled={disabled} className="btn-w-full btn-gray mb-4">
            {mode == "register" ? "Sign Up" : "Sign In"}{" "}
            {mode != "register" &&
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.66039 15.1848C5.26987 14.7943 4.6367 14.7943 4.24618 15.1848C3.85566 15.5753 3.85566 16.2085 4.24618 16.599L5.66039 15.1848ZM4.24618 3.39969C3.85566 3.79021 3.85566 4.42338 4.24618 4.8139C4.6367 5.20442 5.26987 5.20442 5.66039 4.8139L4.24618 3.39969ZM10.4147 6.83519C10.0478 6.42241 9.41574 6.38522 9.00295 6.75214C8.59017 7.11906 8.55299 7.75113 8.91991 8.16392L10.4147 6.83519ZM11.8895 9.99955L12.6369 10.6639C12.9737 10.285 12.9737 9.71407 12.6369 9.33519L11.8895 9.99955ZM8.91991 11.8352C8.55299 12.248 8.59017 12.88 9.00295 13.247C9.41574 13.6139 10.0478 13.5767 10.4147 13.1639L8.91991 11.8352ZM11.7507 10.9996C12.3029 10.9996 12.7507 10.5518 12.7507 9.99955C12.7507 9.44727 12.3029 8.99955 11.7507 8.99955V10.9996ZM1.33398 8.99955C0.7817 8.99955 0.333984 9.44727 0.333984 9.99955C0.333984 10.5518 0.7817 10.9996 1.33398 10.9996V8.99955ZM16.0313 4.8139C18.8951 7.67774 18.8951 12.321 16.0313 15.1848L17.4455 16.599C21.0904 12.9541 21.0904 7.04458 17.4455 3.39969L16.0313 4.8139ZM16.0313 15.1848C13.1674 18.0486 8.52424 18.0486 5.66039 15.1848L4.24618 16.599C7.89107 20.2439 13.8006 20.2439 17.4455 16.599L16.0313 15.1848ZM5.66039 4.8139C8.52424 1.95005 13.1674 1.95005 16.0313 4.8139L17.4455 3.39969C13.8006 -0.245207 7.89107 -0.245208 4.24618 3.39969L5.66039 4.8139ZM8.91991 8.16392L11.1421 10.6639L12.6369 9.33519L10.4147 6.83519L8.91991 8.16392ZM11.1421 9.33519L8.91991 11.8352L10.4147 13.1639L12.6369 10.6639L11.1421 9.33519ZM11.7507 8.99955H1.33398V10.9996H11.7507V8.99955Z"
                  fill="white"
                />
              </svg>
            }
          </button>
        </form>
      )}
      {currentDisplayForm === "otp" && (

        <div className="fixed-fullscreen">
          <div className="login_wrapper mobile-center">
            <div class="fixed-backbtn">
              <i class="fa-solid fa-angle-left me-5 mobile-visible-div jj" onclick="history.back()"></i>


            </div>
            <div className="flex_one">
              <div className="container-lg">
                <div className="sign_in_wrap pt-3 pb-3">
                  <div className="sign_up_form flex_one">
                    {/* <img src={ASSETS.cheezoo_logo_big} className="img-fluid mb-4" /> */}
                    <h2 className="heading2">Enter One-Time Password</h2>
                    <form onSubmit={(e) => handleOtpSubmit(e)}>
                      <div className="otp_fields mt-4 mb-5">
                        <input
                          type="number"
                          onPaste={handlePaste}
                          className="form-control"
                          min={0}
                          max={9}
                          maxLength={1}
                          ref={otp1Ref}
                          required
                          value={formFields.otp1}
                          onChange={(e) => {

                            if (e.target.value && !digitRegex.test(e.target.value)) {
                              return;
                            }

                            setFormFields({
                              ...formFields,
                              otp1: e.target.value
                            });

                            if (e.target.value.length === 1) {
                              otp2Ref.current.focus();
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
                              e.preventDefault();
                            }
                          }}
                        />
                        <input
                          type="number"
                          onPaste={handlePaste}
                          className="form-control"
                          min={0}
                          max={9}
                          maxLength={1}
                          ref={otp2Ref}
                          required
                          value={formFields.otp2}
                          onChange={(e) => {
                            if (e.target.value && !digitRegex.test(e.target.value)) {
                              return;
                            }
                            setFormFields({
                              ...formFields,
                              otp2: e.target.value,
                            });
                            if (e.target.value.length === 1) {
                              otp3Ref.current.focus();
                            }
                          }}
                          onKeyDown={(e) => {
                            handleBackspace(e, otp1Ref)
                          }}
                        />
                        <input
                          type="number"
                          onPaste={handlePaste}
                          className="form-control"
                          min={0}
                          max={9}
                          maxLength={1}
                          ref={otp3Ref}
                          required
                          value={formFields.otp3}
                          onChange={(e) => {
                            if (e.target.value && !digitRegex.test(e.target.value)) {
                              return;
                            }
                            setFormFields({
                              ...formFields,
                              otp3: e.target.value,
                            });
                            if (e.target.value.length === 1) {
                              otp4Ref.current.focus();
                            }
                          }}
                          onKeyDown={(e) => {
                            handleBackspace(e, otp2Ref)
                          }}
                        />
                        <input
                          type="number"
                          onPaste={handlePaste}
                          className="form-control"
                          min={0}
                          max={9}
                          maxLength={1}
                          ref={otp4Ref}
                          required
                          value={formFields.otp4}
                          onChange={(e) => {
                            if (e.target.value && !digitRegex.test(e.target.value)) {
                              return;
                            }
                            setFormFields({
                              ...formFields,
                              otp4: e.target.value,
                            });
                            if (e.target.value.length === 1) {
                              otp5Ref.current.focus();
                            }
                          }}
                          onKeyDown={(e) => {
                            handleBackspace(e, otp3Ref)
                          }}
                        />
                        <input
                          type="number"
                          onPaste={handlePaste}
                          className="form-control"
                          min={0}
                          max={9}
                          maxLength={1}
                          ref={otp5Ref}
                          required
                          value={formFields.otp5}
                          onChange={(e) => {
                            if (e.target.value && !digitRegex.test(e.target.value)) {
                              return;
                            }
                            setFormFields({
                              ...formFields,
                              otp5: e.target.value,
                            });
                            if (e.target.value.length === 1) {
                              otp6Ref.current.focus();
                            }
                          }}
                          onKeyDown={(e) => {
                            handleBackspace(e, otp4Ref)
                          }}
                        />
                        <input
                          type="number"
                          onPaste={handlePaste}
                          className="form-control"
                          min={0}
                          max={9}
                          maxLength={1}
                          ref={otp6Ref}
                          value={formFields.otp6}
                          onChange={(e) => {
                            if (e.target.value && !digitRegex.test(e.target.value)) {
                              return;
                            }
                            setFormFields({
                              ...formFields,
                              otp6: e.target.value.replace(/\D/g, '').slice(0, 1),
                            })
                          }

                          }
                          onKeyDown={(e) => {
                            handleBackspace(e, otp5Ref)
                          }}
                        />
                      </div>
                      <div className="otp-resend">
                        <p>
                          {!isDisabled ? <button
                            type="button"
                            className={"btn-w-full btn-gray"}
                            disabled={resendDisabled}
                            onClick={(e) => {
                              e.preventDefault();
                              //intializeRecaptcha();
                              handlePhoneNumberSubmit(e);
                            }}
                          >
                            Resend OTP
                          </button> :
                            seconds > 0 ? <>Resend OTP in: <span className="underline-grey">00:{seconds}</span></> : ""}
                        </p>
                      </div>
                      <button type="submit" disabled={otpDisabled}  className="btn-w-full btn-gray mb-4 d-flex align-items-center justify-content-center">
                        Continue<i className="fa-solid fa-angle-right mx-2" />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div id="recaptcha-container"></div>
    </div>
  );
};

export default PhoneLoginComponent;
